<template>
  <div>
    <loading v-if="loading" />

    <div v-else class="columns is-multiline is-marginless">
      <div class="column is-12">
        <form @submit.prevent="changePassword">
          <div class="columns is-relative">
            <div class="column">
              <create-password
                ref="createPassword"
                label="New Password"
                confirm
                expand
                @validated="form.newPassword = $event"
              />

              <b-field class="has-text-centered has-margin-top-100">
                <button
                  :class="{ 'is-loading': processing === 'password' }"
                  :disabled="formIsDisabled"
                  :style="{ minWidth: '15rem' }"
                  type="submit"
                  class="button is-primary is-rounded is-medium"
                >
                  <strong class="is-size-6">Change password</strong>
                </button>
              </b-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { doc, getDoc } from "@firebase/firestore";

export default {
  name: "ResetPassword",
  components: {
    "create-password": () => import("@shared/account/_createPassword")
  },
  data() {
    return {
      processing: "",
      loading: true,
      form: {
        newPassword: "",
        hash: this.$route.params.hash
      }
    };
  },
  computed: {
    formIsValid() {
      if (this.$_.isEmpty(this.form.newPassword)) return false;
      return true;
    },
    formIsDisabled() {
      return !this.formIsValid || !!this.processing;
    }
  },
  beforeCreate() {
    getDoc(doc(this.$firestore, `resetHashes/${this.$route.params.hash}`)).then(
      hash => {
        if (!hash.exists()) {
          this.$router.push({ path: "/login" });
          this.$snackbar.open({
            message: "Invalid link",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        }
        if (moment(hash.data().dateExpires.toDate()).isBefore(new Date())) {
          this.$router.push({ path: "/login" });
          this.$snackbar.open({
            message: "Link expired",
            position: "is-bottom",
            type: "is-warning",
            queue: false
          });
        }
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    );
  },
  methods: {
    async changePassword() {
      this.processing = "password";
      try {
        const result = await this.$store.dispatch("reseller/resetPassword", {
          password: this.form.newPassword,
          hash: this.form.hash,
          autologin: true
        });

        this.successMessage(result);

        if (result.data.token) {
          const loginResult = await this.$store.dispatch(
            "auth/loginWithCustomToken",
            { token: result.data.token }
          );
          this.successMessage(loginResult);

          this.$nextTick(() => {
            this.$router.push({ name: `sites` });
          });
        } else {
          this.$nextTick(() => {
            this.$router.push({ path: `/login` });
          });
        }
      } catch (error) {
        this.errorMessage(error);
      }
    },
    successMessage(result) {
      if (result && result.message) {
        this.$toast.open({
          message: result.message,
          position: "is-bottom",
          queue: false
        });
      }
    },
    errorMessage(error) {
      this.processing = "";
      if (error && error.message) {
        this.$snackbar.open({
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
          duration: 10000,
          queue: false
        });
      }
    }
  }
};
</script>
